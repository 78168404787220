.sd_main_heading {
    font-weight: 700;
}

.box_img_clr {
    background: #00149f;
    margin: auto;
    position: relative;
    transition: all 0.2s linear;
    border: 5px solid transparent;
    padding: 7px;
}

.sd_icon_sec p {
    color: #111827;
    font-weight: 600;
}
  
.sd_icon_sec {
    background: #dee1e7;
    border-radius: 10px;
    position: relative;
}

.sd_icon_sec:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-right: 16px solid transparent;
    border-left: 16px solid transparent;
    border-top: 18px solid #dee1e7;
    bottom: -18px;
    margin: auto;
    transition: all 0.2s linear;
}

.sd_price_sec h5 {
    color: #111827;
    opacity: 1;
}

.sd_price_sec p {
    font-size: 14px;
    color: #6e6e6e;
    padding: 10px 0;
    display: inline-block;
}

.sd_price_sec button {
    padding: 0;
    font-size: 14px;
    display: contents;
    font-weight: 600;
    text-transform: none;
}

.servicedetail{
    transition: all 0.2s linear;
}

.add_overflow .service_detail_box {
    overflow: hidden;
}
.servicedetail:hover .sd_icon_sec {
    background: #cc1db9;  
}

.add_overflow .servicedetail:hover .sd_icon_sec {
    background: #dee1e7;  
}

.servicedetail:hover .sd_icon_sec:after {
    border-top: 18px solid #cc1db9;
}

.add_overflow .servicedetail:hover .sd_icon_sec:after {
    border-top: 18px solid #dee1e7;
}

.servicedetail:hover .box_img_clr {
    background: #cc1db9;
    border: 5px solid #fff; 
    padding: 7px;
}

.add_overflow .servicedetail:hover .box_img_clr {
    background: #1d61cc;
    border: 5px solid #1d61cc; 
    padding: 7px;
}
  


/*Modal CSS Start */

.modalBackground {
    width: 100vw;
    height: auto;
    background-color: rgb(41 41 41 / 50%); 
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 70px 0; 
    z-index: 99;
}
  
.modalContainer {
    border-radius: 12px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    display: flex;
    flex-direction: column;  
    position: relative;
    max-width: 600px;
    width: 100%;
    max-height: 500px;
    overflow: initial;
    margin: 100px 0;
}

.modalContainerSec {
    overflow-y: scroll;
    margin: 70px 30px 30px;
}  

.modalContainerSec::-webkit-scrollbar-track {
    background-color: #cfcfcf;
    margin-top: 20px;
}  

.modalContainerSec::-webkit-scrollbar {
    width: 2px;
  }
   
  .modalContainerSec::-webkit-scrollbar-thumb {
    background-color: #1d61cc;
    height: 50px;
  }

  .titleCloseBtn img {
    position: absolute;
    left: 20px;
    top: -60px;
    width: 100px;
    height: 100px;
} 

.modal_body li {
    display: flex;
    align-items: center;
}

.modal_body li {
    display: flex;
    align-items: center;
}

.modal_body li {
    display: flex;
    align-items: center;
}

.modal_body li {
    display: flex;
    align-items: center;
}

body.over_flow {
}

.modal_body li {
    display: flex;
    align-items: center;
}
  
.modalContainer .title {
    display: block;
    text-align: center;
    margin-top: 0px;
} 

.price {
    text-align: center;
}
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button img {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    right: 0;
    left: auto;
}
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }

  .price p {
    font-size: 55px;
    font-weight: 600;
    color: #111827;
}

.sd_popup_text {
    max-width: 530px;
    width: 100%;
}

.body ul li p {
    line-height: 20px;
    padding: 0 0;
    color: #111828;
    margin-left: 5px;
}

body.add_overflow {
    overflow: hidden;
    position: relative;
    z-index: 0;
}

.add_overflow .service_detail_box {
    position: relative;
    z-index: 0;
} 

/* CSS MEDIA QUERY */

@media screen and (max-width: 992px) {
    .titleCloseBtn img {
        left: 10px;
        top: -40px;
        width: 100px;
        height: 100px;
    }
    .modalContainer .title {
        margin-top: 20px; 
    }   
    .modal_body li {
        margin-bottom: 10px;
    } 
  }  

  @media screen and (max-width: 500px){
    .sd_popup_text {
        padding: 20px 0px;
    } 
  }
