img.active_menu {
	opacity: 0;
	visibility: hidden;
	display: none;
	transition: all 0.3s linear;
}

.menu_wrapper a:hover img.active_menu {
	opacity: 1;
	visibility: visible;
	display: inline;
	transition: all 0.3s linear;
}

.menu_wrapper a:hover img.menu_icon {
	opacity: 0;
	visibility: hidden;
	display: none;
	transition: all 0.3s linear;
}
img.menu_icon {
	transition: all 0.3s linear;
}
.menu_box span {
	text-align: left;
	border-radius: 10px;
	width: 65px;
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	transition: all 0.3s linear;
}

/* .menu_box1 span {
	background: #f9f1e4;
} */

/* .menu_wrapper a:hover .menu_box1 span {
	background: #cc881d;
} */

.menu_box2 span {
	background: #ffe9fd;
}

/* .menu_wrapper a:hover .menu_box2 span {
	background: #cc1db9;
} */

.menu_box3 span {
	background: #e4f9e6;
}

/* .menu_wrapper a:hover .menu_box3 span {
	background: #1dcc30;
} */

.menu_box4 span {
	background: #e4ecf9;
}

/* .menu_wrapper a:hover .menu_box4 span {
	background: #1d61cc;
} */

.menu_box5 span {
	background: #e4f6f9;
}

.menu_wrapper a:hover .menu_box5 span {
	background: #1db9cc;
}
.menu_wrapper a:hover .menu_box6 span {
	background: #20b6fb;
}

.menu_box p {
	padding-left: 0;
	padding-right: 0;
}

.menu_box:hover p {
	font-weight: bold;
	transition: all 0.1s linear;
}

/* .menu_box1:hover p {
	color: #cc881d;
}

.menu_box2:hover p {
	color: #cc1db9;
}

.menu_box3:hover p {
	color: #1dcc30;
}

.menu_box4:hover p {
	color: #1d61cc;
}

.menu_box5:hover p {
	color: #1db9cc;
}

.menu_box6:hover p {
	color: #20b6fb;
} */

/*model CSS */

.modal_bg {
	box-shadow: 40px 40px 0px #1d61cc !important;
}

.modal_bg Button {
	background-color: #1d61cc !important;
}

.modal_full_bg {
	background-color: rgb(0 0 0 / 55%);
}

/*model responsive CSS */

@media screen and (max-width: 767px) {
	.modal_bg {
		width: 100% !important;
		box-shadow: none !important;
	}
}

@media screen and (max-width: 400px) {
	.veryfy_btn {
		width: 100% !important;
	}
	.modal_bg Button {
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 467px) {
	.menu_wrapper .Menu_box {
		width: 160px;
	}
	.menu_wrapper .Menu_box .menu_box {
		padding-top: 28px;
		padding-bottom: 22px;
	}
	.menu_box p {
		font-size: 14px;
		padding-top: 8px;
	}
}

@media screen and (max-width: 375px) {
	.menu_wrapper .Menu_box {
		width: 128px !important;
	}
	.menu_wrapper .Menu_box .menu_box {
		padding-top: 22px;
		padding-bottom: 10px;
	}
	.menu_box p {
		font-size: 12px;
		padding-top: 4px;
	}
	.menu_box span {
		width: 56px;
		height: 56px;
	}
}

@media screen and (min-width: 1100px) {
	.menu_wrapper .Menu_box {
		width: 240px !important;
	}
	.menu_wrapper .Menu_box .menu_box {
		padding-top: 46px;
		padding-bottom: 46px;
	}
	.menu_box span {
		width: 75px;
		height: 75px;
	}
	.menu_box p {
		font-size: 20px;
	}
}
