.other_border{
    position: absolute !important;
    top: 20px !important;
    left: 0px !important;
    border-left: 2px solid red !important;
    height: 20px !important;
}
.elpis_work{
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; 
}
.form_home_icon{
  display: flex;
  justify-content: center;
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  top:8px;
}

