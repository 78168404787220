.swiper-slide.swiper-slide-active {
    z-index: 5;
    width: 100% !important;
    max-width: 446px !important;
    height: 550px !important;
    opacity: 1; /* Full opacity for the active slide */
    transform: scale(1) rotate(0deg) translateY(0);
    transition: transform 1s ease-in-out;
     
  }

  .swiper-slide.swiper-slide-active img{
    border-radius: 20px !important;
    border: 4px solid #F5F5F5 !important;
  }
  
  
  .swiper-slide.swiper-slide-next {
    z-index: 4;
    height: 478px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-left: -120px;
  }
 
  .swiper-slide.swiper-slide-next  + .swiper-slide {
    height: 322px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-left: -150px;

  }
 
  .swiper-slide.swiper-slide-next  + *{
    border: 4px solid #F5F5F5;
    border-left: 0px !important;
    border-radius: 0px 20px 20px 0px !important;
  }
 
  
  .swiper-slide {
     transform: translateX(-60px) scale(0.8);
     transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  }
  
  .swiper-slide img {
    display: block;
    object-fit: cover;
    border: 4px solid #F5F5F5;
    border-left: 0px !important;;
    border-radius: 0px 20px 20px 0px !important;
  }
  
  .mySwiper{
    height: 550px !important;
  }

  @media (min-width: 320px) and (max-width: 554px) {
    .swiper-slide.swiper-slide-active {
     
      height: 350px !important;
       
    }
    .mySwiper{
      height: 350px !important;
      max-width: 300px;
    }
    .swiper-slide.swiper-slide-next {
      height: 300px !important;
      margin-left: -120px;
    }
   
    .swiper-slide.swiper-slide-next  + .swiper-slide {
      height: 280px !important;
      margin-left: -150px;
    }
    .arrowDiv{
      left:232px !important;
    }
    .arrowDiv img{
      max-width: 60px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 348px) {
    .arrowDiv{
      left:200px !important;
    }
  }
  .arrow{
    z-index: 99 !important;
  }