/*Setting Page Common CSS*/

/* .tab_wrapper .css-u94v04-MuiTabs-indicator {
	position: absolute;
	height: 100%;
	background-color: #5e4fe9 !important;
	z-index: -1;
	border-radius: 10px;
	transition: all 0.2s linear;
	width: 180px !important;
} */

.tab_wrapper button {
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	padding: 6px 18px !important;
}

/* .tab_wrapper .Mui-selected {
	color: #ffffff !important;
} */

input:focus-visible,
input:hover {
	outline: none;
}

.edit_info_spacing input,
.edit_info_spacing textarea {
	padding: 12px 20px !important;
	background: #f9fafb !important;
}

.edit_info_spacing textarea {
	border: 1px solid #c8c8c8 !important;
	border-radius: 5px !important;
	margin-top: 10px;
}

p {
	margin: 0;
	padding: 0;
	display: block;
}
.proImage img {
	max-width: 150px;
	min-height: 150px;
	max-height: 150px;
	border-radius: 50%;
}

/*Setting Page Detail Tab CSS*/

.setting_btn {
	background-color: #1d61cc !important;
	color: #fff !important;
	margin-left: 20px !important;
	margin-top: 20px !important;
}

.edit_link {
	color: #525052 !important;
}

.profile_wrapper {
	position: relative;
}

/* .detail_wrapper:after,
.profile_wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 95px;
  width: 100%;
  height: 1px;
  background: #cecece;
} */

.textarea_box {
	width: 100%;
}

.ques_ans label {
	width: 70px;
}

.setting_btn1 {
	background: #ffffff !important;
	color: #5e4fe9 !important;
	border: 1px solid #5e4fe9 !important;
	margin-left: 15px !important;
}

.upload_box input[type='file'] {
	display: none;
}

.upload_wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.image_box img {
	width: 100%;
	height: 100%;
	border-radius: 8px;
	position: absolute;
	top: -10px;
	left: -10px;
	object-fit: cover;
}

label.customFileUpload {
	font-size: 16px;
	font-weight: 600;
}
.upload_box {
	position: relative;
	width: 115px;
	height: 115px;
	border-radius: 8px;
	background-image: url('../../img/image-gallery.png');
}
.upload_box2 {
	position: relative;
	width: 115px;
	height: 115px;
	border-radius: 8px;
	background-image: url('../../img/user-detail1.png');
}

.setting_wrapper .css-19kzrtu {
	padding: 0 !important;
}

.setting_wrapper .css-1mec7zx-MuiTabs-indicator {
	display: none;
}

.setting_wrapper .css-lfwcke-MuiTabs-flexContainer {
	align-items: flex-start !important;
}

.css-1snpn6h-MuiTabs-root {
	position: sticky;
	top: 8px;
}

.setting_wrapper .css-z6cxzb-MuiButtonBase-root-MuiTab-root {
	display: -webkit-inline-box !important;
	display: -webkit-flex !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-align-items: flex-start !important;
	-webkit-box-align: flex-start !important;
	-ms-flex-align: flex-start !important;
	align-items: flex-start !important;
	-webkit-justify-content: flex-start !important;
	justify-content: flex-start !important;
	/* max-width: 238px !important; */
	/* min-width: 192px !important; */
	width: 100%;
	line-height: 2.25;
	text-align: left !important;
	align-items: flex-start !important;
}

.detail_wrapper .css-z6cxzb-MuiButtonBase-root-MuiTab-root {
	display: -webkit-inline-box !important;
	display: flex !important;
	-webkit-align-items: flex-start !important;
	-webkit-box-align: flex-start !important;
	-ms-flex-align: flex-start !important;
	justify-content: flex-start !important;
	max-width: 212px !important;
	/* min-width: 212px !important; */
	width: 100%;
	line-height: 2.25;
	text-align: center !important;
	align-items: center !important;
}

.detail_wrapper .css-u94v04-MuiTabs-indicator {
	display: none;
}

.detail_wrapper .css-heg063-MuiTabs-flexContainer {
	justify-content: center !important;
}

.setting_wrapper .css-z6cxzb-MuiButtonBase-root-MuiTab-root.Mui-selected {
	background-color: #1d61cc;
	color: white;
}

.setting_wrapper .css-1snpn6h-MuiTabs-root {
	max-height: 146px !important;
}

.profile_wrapper {
	border-top: 12px solid #1d61cc;
	border-radius: 11px;
}

label.customFileUpload {
	font-size: 16px;
	font-weight: 600;
	position: absolute;
	left: 130px;
	width: 150px;
	top: 40px;
	cursor: pointer;
}

label.customHeadshotUpload {
	left: -194px;
	width: 180px;
}

.upload_box button {
	bottom: 25px;
	position: absolute;
	left: 150px;
	font-size: 16px;
	color: #df1717;
}

.upload_box2 button {
	left: -138px !important;
}

.image_box {
	width: 115px;
	height: 115px;
	border: 1px solid whitesmoke;
}
/* .image_box {
	width: 115px;
	height: 115px;
	border-radius: 50%;
	background: white;
	border: 1px solid whitesmoke;
} */

.pawicon {
	position: relative;
}

.pawicon button {
	/* position: absolute; */
	right: 10px;
	width: 50px !important;
	color: #363636;
}

.pawicon .css-xjhh89-MuiInputBase-root-MuiOutlinedInput-root {
	padding: 0;
	background: #adadad;
}

.crm_login_password .pawicon button {
	right: 10px;
}
.pawicon .css-xjhh89-MuiInputBase-root-MuiOutlinedInput-root {
	padding: 0;
}
.passwordError {
	font-size: 14px;
	padding-top: 5px;
	color: #d30000;
}

.ques_ans {
	display: flex;
	align-items: baseline;
}

.loaderImage {
	position: absolute;
	left: 0px;
	right: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	filter: invert(1);
	background: #323232;
}

button.setting_cancel_btn {
	color: #1d61cc !important;
	border: 1px solid #1d61cc !important;
	background-color: #b3cffa2b !important;
}

.edit_info_spacing label,
.edit_info_spacing h3 {
	font-size: 15px;
	font-weight: 600;
	color: #1c1c1c;
	line-height: 30px;
}

.setting_heading h3 {
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 50px;
	position: relative;
}

/* .setting_heading h3:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 80%;
    height: 1px;
    background: #adadad;
    bottom: -10px;
} */

/* [aria-invalid="false"] {
    opacity:1 ;
} */
/*Setting responsive CSS */

/* css for logo error alert */

.logo_error_alert button {
	width: 100% !important;
	padding: 9px 7px !important;
}

.logo_error_alert .css-1ytlwq5-MuiAlert-icon {
	margin-right: 2px !important;
}

.logo_error_alert .css-ki1hdl-MuiAlert-action {
	padding: 0 !important;
}

.logo_error_alert .css-xilmwd-MuiPaper-root-MuiAlert-root {
	padding: 0px 10px !important;
}

/* css for headshot error alert */

.headshot_error_alert button {
	width: 100% !important;
	padding: 9px 7px !important;
}

.headshot_error_alert .css-1ytlwq5-MuiAlert-icon {
	margin-right: 2px !important;
}

.headshot_error_alert .css-xilmwd-MuiPaper-root-MuiAlert-root {
	padding: 0px 10px !important;
}

.headshot_error_alert .css-ki1hdl-MuiAlert-action {
	padding: 0 !important;
}

/* css for generic error box */
.generic_error .css-acap47-MuiAlert-message {
	margin: auto !important;
}

.generic_error button {
	width: 100% !important;
}

.headShot_upload_block {
	text-align: -webkit-right;
	text-align: -moz-right;
}

@media screen and (max-width: 1200px) {
	.error_display_manage {
		display: block !important;
	}

	.error_display_manage .headShot_upload_block {
		text-align: inherit;
		padding-top: 10px;
	}

	.error_display_manage
		.headShot_upload_block
		.css-1hdyuqk-MuiPaper-root-MuiAlert-root {
		left: 0;
		top: 136px;
	}

	.error_display_manage label.customHeadshotUpload {
		left: 130px;
	}

	.error_display_manage .upload_box2 button {
		left: 150px !important;
	}

	.error_display_manage .logo_alert_responsive {
		margin-top: 4rem;
	}

	.error_display_manage
		.headshot_error_alert
		.css-xilmwd-MuiPaper-root-MuiAlert-root {
		top: 18rem !important;
		left: 0;
	}

	.error_display_manage .headshot_alert_responsive {
		margin-top: 4rem;
	}
}

@media screen and (max-width: 1050px) {
	.textarea_box {
		width: 100%;
	}
}

@media screen and (max-width: 1024px) {
	.detail_wrapper .css-heg063-MuiTabs-flexContainer {
		flex-wrap: wrap !important;
	}
}

@media screen and (min-width: 785px) and (max-width: 1024px) {
	.setting_form_wrapper .css-z6cxzb-MuiButtonBase-root-MuiTab-root {
		max-width: 140px !important;
		min-width: none;
		width: 100%;
	}
}

@media screen and (max-width: 991px) {
	.upload_wrapper {
		display: block;
	}

	label.customHeadshotUpload {
		left: 130px !important;
	}

	.headShot_upload_block {
		text-align: inherit;
		padding-top: 10px;
	}

	.headShot_upload_block .css-1hdyuqk-MuiPaper-root-MuiAlert-root {
		left: 0;
		top: 136px;
	}

	.upload_box2 button {
		left: 160px !important;
	}

	.logo_alert_responsive {
		margin-top: 4rem;
	}
	.headshot_error_alert .css-xilmwd-MuiPaper-root-MuiAlert-root {
		top: 18rem !important;
		left: 0;
	}
	.headshot_alert_responsive {
		margin-top: 4rem;
	}
}

@media screen and (max-width: 767px) {
	.tab_wrapper NavLink {
		width: auto !important;
	}
	.tab_wrapper .css-u94v04-MuiTabs-indicator {
		width: auto !important;
	}
	.detail_btn_box {
		padding-top: 30px;
	}
	.detail_sec2 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
		display: flex;
	}

	.headShot_upload_block {
		text-align: inherit;
		padding-top: 20px !important;
	}

	.headShot_upload_block .css-1hdyuqk-MuiPaper-root-MuiAlert-root {
		left: 0;
		top: 145px !important;
	}
}

@media screen and (max-width: 500px) {
	.css-1pz56y3-MuiGrid-root {
		-webkit-flex-basis: 100% !important;
		-ms-flex-preferred-size: 100% !important;
		flex-basis: 100% !important;
		max-width: 100% !important;
	}
	.css-12lw404-MuiGrid-root {
		-webkit-flex-basis: 100% !important;
		-ms-flex-preferred-size: 100% !important;
		flex-basis: 100% !important;
		max-width: 100% !important;
	}

	.setting_wrapper .css-z6cxzb-MuiButtonBase-root-MuiTab-root {
		max-width: 100% !important;
		-webkit-align-items: center !important;
		text-align: center !important;
		align-items: center !important;
	}
	.profile_img p {
		display: block;
	}
	.profile_img span {
		font-size: 16px !important;
	}
	.tab_wrapper NavLink {
		padding: 0;
	}

	.logo_alert_responsive {
		margin-top: 5rem;
	}
	.tab_wrapper button {
		font-size: 16px;
	}
	.css-1pz56y3-MuiGrid-root {
		background-color: white;
		position: sticky;
		top: 0px;
		z-index: 99;
	}
	.setting_options {
		padding-top: 4px !important;
	}
	.detail_box .css-19kzrtu {
		position: sticky;
		top: 0px;
		z-index: 999;
		background: white;
	}
	.detail_wrapper .css-ccd0hp-MuiTabs-root {
		position: sticky;
		background: white;
		z-index: 9999;
		top: 0;
		padding-top: 8px;
		border-radius: 6px;
		box-shadow: 0px 3px 3px #dcdcdc;
	}
}
@media screen and (max-width: 375px) {
	label.customFileUpload {
		top: auto;
		bottom: -25px;
		left: 0;
	}
	label.customHeadshotUpload {
		left: 0px !important;
		top: 120px;
	}
	.upload_box button {
		font-size: 14px;
		left: -16px !important;
		bottom: -50px;
	}
	.error_display_manage .upload_box {
		margin-bottom: 100px;
		margin-top: 0px !important;
	}

	.bottom_margin {
		margin-bottom: 100px !important;
		margin-top: 0px !important;
	}

	.upload_box {
		margin-bottom: 20px;
		margin-top: 0px !important;
	}

	.headShot_upload_block .css-1hdyuqk-MuiPaper-root-MuiAlert-root {
		top: 180px !important;
	}

	.logo_upload_block .css-1hdyuqk-MuiPaper-root-MuiAlert-root {
		top: 160px !important;
	}
	.error_display_manage .upload_box2 button {
		left: -16px !important;
	}
}
