body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .size-full {
    width: 100%;
    height: 100%;
  }
  .image-unset {
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
  }
}

@font-face {
  font-family: "LeJour";
  src: url("/src/assets/fonts/LeJour-Regular.woff2") format("woff2"),
    url("/src/assets/fonts/LeJour-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Glacial Indifference";
  src: url("/src/assets/fonts/GlacialIndifference-Regular.woff2")
      format("woff2"),
    url("/src/assets/fonts/GlacialIndifference-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Glacial Indifference";
  src: url("/src/assets/fonts/GlacialIndifference-Bold.woff2") format("woff2"),
    url("/src/assets/fonts/GlacialIndifference-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nourd-Regular';
  src: url('/src/assets/fonts/NOURD_REGULAR.woff2') format('woff2'),
       url('/src/assets/fonts/NOURD_REGULAR.woff') format('woff');
  font-weight: 400; /* Adjust weight if necessary */
  font-style: normal;
}

@font-face {
  font-family: 'Nourd-Medium';
  src: url('/src/assets/fonts/NOURD_MEDIUM.woff2') format('woff2'),
       url('/src/assets/fonts/NOURD_MEDIUM.woff') format('woff');
  font-weight: 500; /* Adjust weight if necessary */
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('/src/assets/fonts/Montserrat-Regular.woff2') format('woff2'),
       url('/src/assets/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: 400; /* Adjust weight if necessary */
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('/src/assets/fonts/Montserrat-Medium.woff2') format('woff2'),
       url('/src/assets/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500; /* Adjust weight if necessary */
  font-style: normal;
}

@font-face {
  font-family: 'Canela-Trial';
  src: url('/src/assets/fonts/Canela-Light-Trial.woff') format('woff2');
  font-weight: 400; /* Adjust weight if necessary */
  font-style: normal;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

.slick-cloned{
  width: unset !important;
}
.slick-slide{
  width: unset !important;
}
li::marker {
  color:  #474747; /* Change marker color to red */
}