.bg-real-estate:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent; 
}

.bg-real-estate .css-ryciwz-MuiBackdrop-root {
    background-color: transparent !important;
}