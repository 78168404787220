.email_confirm_wrapper:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    width: 100%;
    height: 100%;
    background: url('../img/circle_shape_img.png');
    margin: auto;
    z-index: -1;
    transform: rotate(0deg);
    background-repeat: no-repeat; 
    background-position: top;
}

.email_confirm_wrapper Button{
 background-color: #1d61cc !important;
}

h1.emailverify_heading {
    margin: 0px 0 10px;
}
/*email confirmation responsive CSS */

@media screen and (max-width: 500px) {
.email_confirm_wrapper Button {
    width: 100% !important; 
}  
}

@media screen and (max-width: 700px) {
    h1.emailverify_heading {
        font-size: 44px;
    } 
    }