a.client_view_detail {
  background: #5e4fe9;
  color: #fff;
}

.client_info_box {
  box-shadow: 0px 0px 15px #e3e3e3;
  padding: 20px 20px 30px 20px;
  border-radius: 7px;
}

.client_info_box img {
  width: 75%;
  margin: auto;
}

.admin_logo {
  width: 110px;
  height: 110px;
  margin: auto;
  border-radius: 12px;
  /* background-image: url("../../img/user-detail1.png");
    background-position: bottom; */
  position: relative;
}

.admin_logo img {
  width: 100%;
  height: 100%;
  margin: 0;
  border: none !important;
  object-fit: cover;
}

.logo_headshot {
  display: block;
}

.logo_headshot p {
  color: #1c1c1c;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 28px;
}

.logo_headshot label {
  line-height: 40px;
  cursor: pointer;
  position: relative;
}

.logo_Box,
.headshot_Box {
  position: relative;
}

.logo_Box .remove_button {
  position: absolute;
  top: 14px;
  left: 0px;
  line-height: 28px;
}

.headshot_Box .remove_button2 {
  position: absolute;
  top: 14px;
  left: 0px;
  line-height: 30px;
}

.logo_headshot input[type="file"] {
  display: none;
}

p.headshot_heading {
  margin-top: 16px;
}
/* ==========Client Detail CSS============ */

.client_info_left img {
  border: 4px solid #e3e3e3;
}

.client_info_left_subs ul {
  list-style: disc;
  text-align: left;
  margin-bottom: 15px;
  padding: 0 20px;
}

.client_info_left_subs ul p {
  font-size: 15px;
}

.client_info_left_txtfld p {
  word-spacing: 5px;
  line-height: 22px;
  font-size: 16px;
}

.client_info_left_txtfld {
  position: relative;
  padding-bottom: 20px;
}

.client_info_left_txtfld span {
  position: absolute;
  right: 0;
}

/* ========Client Tab Section=========== */

.client_tablepane_wrapper {
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgb(120 133 146 / 20%);
}

.client_btn {
  background: transparent !important;
  box-shadow: none !important;
}

.client_btn {
  background: transparent;
  box-shadow: none;
}

.client_notes_btn h5 {
  color: #27344b;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Client_btn {
  background: #5e4fe9 !important;
  color: #fff !important;
  padding: 10px 70px !important;
  margin-top: 30px !important;
  margin-left: 10px !important;
  padding: 0 !important;
  font-size: 16px !important;
  min-width: 100px !important;
  height: 45px;
}

.Client_btn:hover {
  background: #cc1db9 !important;
}

.hashtag_show {
  display: block;
}

.hashtag_hide {
  display: none;
}

.edit_info_spacing input {
  padding: 12px 20px !important;
  background: #fafafa !important;
}

.SideMenu_box svg {
  color: #a3a9b5;
  font-size: 30px;
}

.sidebar_toggle img {
  width: 32px;
  height: 26px;
  border-radius: 0;
  box-shadow: none;
  opacity: 1;
  cursor: pointer;
}

.sidebar_toggle {
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
}

.hashtag_show button {
  color: #5e4fe9 !important;
  font-size: 16px;
}

button.hashtag_btn {
  color: gray !important;
}

button.client_cancel_btn {
  background: gray !important;
}

.client_list_container .dashboard_row {
  display: block;
}

.client_list_container .dashboard_col2 {
  display: block;
}

.search_client {
  position: relative;
}

.search_client input#search {
  padding-left: 40px;
  height: 0px;
  padding: 19px 20px 23px 40px;
  border-radius: 5px;
}

.client_list_page {
  width: 100%;
}

.client_info_grid {
  margin-top: 30px;
}

.load_more_btn button {
  display: flex;
  margin: 30px auto 0px;
  background: #c307ae;
  color: #fff;
  padding: 8px 25px;
  font-weight: 600;
  display: none;
}

.load_more_btn button:hover {
  background: #c307ae;
  color: #fff;
}

.search_client span {
  position: absolute;
  top: 4px;
  left: 10px;
}

.search_client .css-sq8to5-MuiInputBase-root-MuiOutlinedInput-root {
  width: 350px;
  background: #fff;
}

.search_client span svg {
  color: #bbbbbb;
}

.client_view_detail {
  font-size: 15px !important;
}

.client_info_box p {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit_notes_fiels input#clientNotes {
  height: 150px;
}

/* .css-1o2jng6-MuiOutlinedInput-notchedOutline {
    top: 0px !important;
} */

.client_detail_row {
  display: flex;
  justify-content: center;
}

.client_detail_col1 {
  width: 100%;
  max-width: 280px;
  margin-right: 30px;
}

.client_font_stypeP p {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

textarea#editHashtag {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fafafa;
}

.download_logo {
  cursor: pointer;
  position: absolute;
  bottom: -12px;
  left: 100px;
}

.download_headshot {
  cursor: pointer;
  position: absolute;
  bottom: -12px;
  left: 100px;
}

.client_headshot span {
  width: 130px;
  height: 130px;
  background: #f7f7f7;
  display: block;
  border-radius: 50%;
  display: flex;
  margin: auto;
  border: 1px solid #efefef;
  padding: 0px;
}

.client_headshot img {
  width: 95%;
  height: 95%;
  margin: auto;
  object-fit: cover;
  border-radius: 50%;
}

.client_detail_row .css-heg063-MuiTabs-flexContainer {
  flex-wrap: wrap;
}

.client_dtl_tabs .css-z6cxzb-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #cc1db9;
  border-bottom: 2px solid #cc1db9;
}

.client_dtl_tabs .css-u94v04-MuiTabs-indicator {
  display: none;
}

.client_detail_col2 .css-19kzrtu {
  padding: 14px;
}

/* ===========Responsive CSS=============== */

@media screen and (max-width: 1250px) {
  /* .form_box {
    width: 50%;
    max-width: 50% !important;
    flex-basis: 49.333333% !important;
  } */

  .edit_info_spacing label {
    font-size: 13px !important;
  }

  .client_detail_col1 {
    max-width: 250px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1024px) {
  .client_detail_col2 .css-19kzrtu {
    padding: 14px 10px;
  }
}

/* @media screen and (max-width: 1200px) {
  .client_info_box h6,
  .client_info_box p {
    font-size: 16px;
  }

  .client_font_stypeP p {
    font-size: 16px;
    text-overflow: ellipsis;
    max-width: 100%;
  }
} */

@media screen and (max-width: 991px) {
  .client_detail_row {
    display: block;
  }

  .logo_headshot {
    display: block;
  }

  .client_detail_col1 {
    max-width: 100%;
    margin: 0 auto 20px;
  }
  .client_info_left_subs ul {
    list-style: none;
    text-align: center;
    margin-bottom: 15px;
    padding: 0 20px;
  }
}

/* @media screen and (max-width: 767px) {
  .client_detail_col1 {
    max-width: 100%;
  }

  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .client_detail_col1 {
    max-width: 100%;
  }

  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    flex-wrap: wrap;
  }

  .search_client .css-sq8to5-MuiInputBase-root-MuiOutlinedInput-root {
    width: 250px;
  }
}

@media screen and (max-width: 400px) {
  .search_client .css-sq8to5-MuiInputBase-root-MuiOutlinedInput-root {
    width: 225px;
  }
}

@media screen and (max-width: 767px) {
  .form_box {
    width: 50%;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
} */
