.client_list_container {
  overflow-x: hidden;
}

.sidebar_wrapper {
  width: 300px;
  position: fixed;
  left: 0;
  height: 100vh;
  padding: 20px;
  box-shadow: 0px 0px 10px gainsboro;
  transition: all 0.2s linear;
  overflow-y: auto;
  z-index: 9;
  background: #fff;
  overflow-x: hidden;
  top: 100px;
}

.css-i4bv87-MuiSvgIcon-root {
  color: #000;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-1ur0h4m-MuiToolbar-root {
  min-height: 0;
  background-color: 0;
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px;
}

img.side_menu_icon_active {
  display: none;
}

.active img.side_menu_icon_active {
  display: block;
  background: #cc1db9;
  padding: 10px;
}

.active img.side_menu_icon {
  display: none;
}

.active .Side_menu_box {
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0px 0px 5px 0px rgb(120 133 146 / 15%);
}

.SideMenu_box svg {
  color: #a3a9b5;
  font-size: 30px;
}

.sidebar_wrapper.click-state {
  left: -215px;
  transition: all 0.2s linear;
}

.sidebar_section {
  display: block;
  transition: all 0.2s linear;
}

.click-state .sidebar_section {
  display: block;
}

.click-state span.sidebar_icon {
  position: absolute;
  right: 20px;
}

.click-state li {
  margin-bottom: 20px;
}

/* ==========Responsive CSS=========== */

@media screen and (max-width: 767px) {
  .side_bar_responsive .dashboard_col2 {
    margin-left: 250px;
  }

  .click-state span.sidebar_icon {
    right: 8px;
  }

  .sidebar_toggle {
    justify-content: center !important;
  }

  .click-state .sidebar_toggle img {
    height: 20px;
  }

  .add_space .dashboard_col2 {
    margin-left: 95px !important;
  }

  .add_space .sm\:py-6.py-4.w-full.flex.items-center.justify-between {
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 9999;
    left: 0;
    box-shadow: 0px 0px 15px #eeee;
    padding: 10px;
    z-index: 1;
  }

  .add_space .dashboard_wrapper {
    margin-top: 100px;
  }

  .sidebar_toggle {
    justify-content: right !important;
  }

  /* img[alt="Jane Logo"] {
        width: 60px;
    } */

  .sidebar_section p {
    font-size: 15px;
  }

  .sidebar_wrapper {
    width: 220px;
    padding: 10px 10px 10px 0;
  }

  .sidebar_wrapper.click-state {
    left: -155px;
  }
}

@media screen and (max-width: 550px) {
  .side_bar_responsive .dashboard_col2 {
    margin-left: 30px;
  }

  .sidebar_wrapper {
    width: 220px;
  }

  .sidebar_wrapper {
    width: 100%;
    top: 76px;
  }

  .add_space .sidebar_section p {
    display: none;
  }

  .add_space .SideMenu_box svg {
    display: none;
  }

  .sidebar_wrapper.click-state {
    left: 0;
    top: 59px;
  }

  .add_space .sidebar_wrapper {
    width: 70px;
  }

  .sidebar_wrapper {
    width: 100%;
  }

  .side_bar_responsive .dashboard_col2 {
    margin-left: 100px;
  }

  .active .Side_menu_box {
    padding: 20px;
  }

  .click-state li {
    margin-bottom: 30px;
  }
}
