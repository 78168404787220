.dashboard_service_box {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgb(146 161 177 / 15%);
}

.dashboard_admin_services li {
  width: 50%;
  padding-right: 20px;
  padding-bottom: 20px;
}

.dashboard_service_list h5 {
  font-size: 22px;
}

.dashboard_service_list_box {
  box-shadow: 0px 0px 15px #e5e5e5;
  padding: 15px;
  margin-top: 30px;
}

ul.dashboard_service_list_head h5 {
  font-size: 16px;
  color: #000;
  opacity: 1;
  font-weight: 500;
}

ul.dashboard_service_list_info li {
  color: #b7b7b7;
  opacity: 0.8;
}

.dashboard_service_name p {
  font-size: 14px;
  color: #677791;
}

.dashboard_service_name span {
  color: #344767;
  font-size: 14px;
  font-weight: 700;
}

.dashboard_service_name img {
  background-color: #5e4fe9;
}

ul.dashboard_service_list_info li:nth-child(even) {
  background: #f8f9fa;
  /* color: #202937; */
}

ul.dashboard_service_list_info p {
  color: #202937;
  font-size: 15px;
}

.click-state p {
  display: none;
}

.click-state .SideMenu_box svg {
  opacity: 0;
}

.dashboard_globe {
  width: 100%;
  max-width: 94%;
  animation: float 6s ease-in-out infinite;
  overflow: hidden;
}

ul.dashboard_service_list_head h5 {
  color: #202937;
  font-weight: 700;
}

@keyframes float {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.dashboard_row {
  display: flex;
  justify-content: normal;
  width: 100%;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 30px;
}

.dashboard_col2 {
  display: flex;
  justify-content: space-between;
  transition: all 0.2s linear;
  margin-left: 310px;
}

.add_space .dashboard_col2 {
  margin-left: 100px;
  position: relative;
  /* left: 0; */
}

.dashboard_col {
  padding-right: 15px;
  padding-left: 15px;
}
/* ============Responsive CSS============ */

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1200px) {
  .dashboard_admin_services li {
    width: 100%;
    padding-right: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard_grid3 {
    display: none;
  }

  .dashboard_row {
    display: block;
  }
  .dashboard_col2 {
    margin-left: 335px;
  }
  .add_space .dashboard_col2 {
    margin-left: 125px;
  }
}

@media screen and (max-width: 450px) {
  .add_space .dashboard_col2 {
    margin-left: 30px;
  }

  .dashboard_col2 {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard_grid3 {
    display: none;
  }

  .dashboard_row {
    display: block;
  }
  .dashboard_col2 {
    margin-left: 335px;
  }
  .add_space .dashboard_col2 {
    margin-left: 125px;
  }
}

@media screen and (max-width: 450px) {
  .add_space .dashboard_col2 {
    margin-left: 30px;
  }

  .dashboard_col2 {
    margin-left: 30px;
  }
}

@media screen and (max-width: 375px) {
  .dashboard_service_name p {
    font-size: 12px;
  }
}
